import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import ButtonSimple from '../components/ButtonSimple'
import Container from '../components/Container'

const Component = styled.div`
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Footer = styled.div`
  margin-top: 2rem;
`

// TODO: pimp me
const NotFoundView = () => {
  return (
    <Container>
      <Component>
        <h1>
          This page does not exist.
        </h1>
        <Footer>
          <Link
            href='/'
            passHref
          >
            <a>
              <ButtonSimple
                rounded
                variant={ButtonSimple.VARIANT_CRAZY}
              >
                Go to home
              </ButtonSimple>
            </a>
          </Link>
        </Footer>
      </Component>
    </Container>
  )
}

export default NotFoundView
